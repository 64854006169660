<template>
  <div></div>
</template>
<script>
export default {
  async mounted() {
    const query = this.$route.query
    const redirectUri = `${window.location.origin}/oaSso/loginEnd?redirect=${encodeURIComponent(query.redirect || '')}`
    try {
      const url = await this.getOaLoginUrl({ redirectUri })
      window.location.replace(url)
    } catch (error) {
      this.$popErrorPage({
        message: error.message,
      })
    }
  },
  methods: {
    async getOaLoginUrl(postParams) {
      const res = await this.$axios.post('/ums/weblogin/getCode', postParams)
      return res.data
    },
  },
}
</script>
